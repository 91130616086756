(function ($) {
  // When the menu changes, trigger skuSelect:
  $(document).on('change', 'select.js-sku-menu', function () {
    var skuId = $(this).val();
    var skuBaseId = skuId - 0; // Convert to INT
    var $sppContainer = $(this).closest('.js-product').parent();

    $sppContainer.children('.js-product').trigger('product.skuSelect', [skuBaseId]);
  });

  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('select.js-sku-menu', this).val(skuBaseId);
    $('select.js-sku-menu.selectBox-attached', this).selectBox('value', skuBaseId);

    var $product = $(this);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var sku = prodcat.data.getSku(skuBaseId);
    /* ------- Sku Images ------- */
    var product_full_image = site.template.get({
      name: 'product_full_image',
      data: sku ? sku : prod.defaultSku ? prod.defaultSku : prod // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    });

    $('.product-full__image', $product).each(function() {
      $(this).html($(product_full_image).html());
      // Refresh the slick slider if there is one
      Drupal.behaviors.productFullImageV1.attach($product);
    });

    /* ------- Sku Prices ------- */
    var product_sku_price = site.template.get({
      name: 'product_sku_price',
      data: sku // Only pare the sku because if sku is 0 then the price will be rendered empty
    });

    $('.product-sku-price', $product).each(function() {
        $(this).html($(product_sku_price).html());
      });

    /* ------- Sku Sizes ------- */
    var product_sku_size = site.template.get({
      name: 'product_sku_size',
      data: sku
    });

    $('.product-sku-size', $product).each(function() {
        $(this).html($(product_sku_size).html());
      });

    /* ------- Sku Accordion ------- */
    // Build the data from sku or product
    var product_accordion_area = site.template.get({
      name: 'product_accordion_area',
      data: sku && (sku.SHORT_DESC || sku.DESCRIPTION || sku.ATTRIBUTE_DESC_1) ? sku : prod // Parse the SKU Description if there is one and show the SKU details, otherwise parse the PROD and we'll show the default description for the product
    });

    $('.product-accordion', $product).each(function() {
        $(this).html($(product_accordion_area).html());
        // Refresh the accordion if there is one
        Drupal.behaviors.productAccordionV1.attach();
      });
  });
})(jQuery);
